import React from "react";
import clover from "remote-pay-cloud";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./style.css";
import DeviceRow from "./Component/DeviceRow";
import Store from "./models/Store";
import Connect from "./utils/CloverConnection";
import CurrencyFormatter from "./utils/CurrencyFormatter";
import Modal from "./Component/Modal";
import { myConfig } from "./config";
import * as htmlToImage from "html-to-image";
import HtmlPrint from "./Component/HtmlPrint";

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function validateAmount(amnt) {
  return amnt && !isNaN(amnt) ? amnt : 0;
}

const store = new Store();
const formatter = new CurrencyFormatter();
const TOTAL_AMOUNT = validateAmount(myConfig.totalAmount);
const THRESHOLD_AMOUNT = validateAmount(
  myConfig.clover_signature_threshold_amount
);
const TIP_SUGGESTIONS =
  myConfig.tipSuggestions &&
  isJson(myConfig.tipSuggestions) &&
  Array.isArray(JSON.parse(myConfig.tipSuggestions))
    ? JSON.parse(myConfig.tipSuggestions)
    : [];
const TIP_IN_PERCENT = myConfig.isTipInPercentage == "1";
const IS_AUTH = myConfig.isAuth == "1";
const CLOVER_PAYMENT_ID = myConfig.cloverPaymentId;
const CLOVER_ORDER_ID = myConfig.cloverOrderId;
const CLOVER_REFUND_ID = myConfig.cloverRefundId;
const IS_TAAR = myConfig.isTAAR == "1";
const DROR_ACTION = ["Refund", "Payment"][myConfig.drorAction];
const IS_REFUND = myConfig.isRefund == "1";
const IS_FULL_REFUND = myConfig.isFullRefund == "1";
const IS_VOID = myConfig.isVoid == "1";
const TAAR_AMOUNT = validateAmount(myConfig.taarAmount);
const IS_CLOSEOUT = myConfig.isCloseout == "1";
const IS_PRINT_IMAGE = Boolean(myConfig.print_image == "1");
const CLOSEOUT_TIP_ADJUST = {
  ticketNo: (myConfig?.transaction_ticket_no ?? "").split(",").filter((v) => v),
  transactionNo: (myConfig?.transaction_reference_no ?? "")
    .split(",")
    .filter((v) => v),
  payId: (myConfig?.cloverPaymentId ?? "").split(",").filter((v) => v),
  orderId: (myConfig?.cloverOrderId ?? "").split(",").filter((v) => v),
  amount: (myConfig?.taarAmount ?? "").split(",").filter((v) => v),
};
const CLOVER_RESPONSE_IN_POST = myConfig.cloverResponseInPost == "1";

const App = () => {
  const [devices, setDevices] = React.useState();
  const [cloudConnect, connectToCloud] = React.useState(false);
  const [connected, toggleConnectionState] = React.useState(false);
  const [bulkTipAdjustResponse, setBulkTipAdjustResponse] = React.useState([]);
  const [tipAmount, tipAdded] = React.useState(0);
  const [pairingCode, setPairingCode] = React.useState("");
  const [cloverConnection, setCloverConnection] = React.useState();
  const [selectedKit, selectKit] = React.useState();
  const [responseToBE, setResponseToBE] = React.useState();
  const autoClickRef = React.useRef();
  const [autoClick, setAutoClick] = React.useState();
  const [isAuthincation, SetIsAuthincation] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [updateSuccess, setUpdateSuccess] = React.useState(false); // Track success

  const [htmlString, setHtmlString] = React.useState();
  const [initialStaus, setInitialStaus] = React.useState("Initializing...");
  const [otherState, setOtherState] = React.useState({
    statusArray: null,
    statusText: "",
    statusToggle: false,
    responseFail: false,
    refundSuccess: false,
    tipAdjust: false,
    vaultedCard: false,
    newCustomMessage: false,
    finalCustomMessage: false,
    inputOptions: null,
    challenge: false,
    saleFinished: false,
    preAuth: false,
    response: false,
    request: null,
    challengeContent: null,
    printers: [],
    signatureRequest: null,
    showSignature: false,
    customSuccess: false,
    finalMessageFromCustomActivity: "",
    messageFromCustomActivity: "",
  });

  React.useEffect(() => {
    if (!responseToBE) return;
    console.log(
      "responseToBE",
      responseToBE,
      myConfig.transaction_ticket_no,
      myConfig.transaction_reference_no,
      myConfig.isFullRefund
    );
    const sendRespToBE = async () => {
      let respToBEForCloseOut;
      if (IS_CLOSEOUT) {
        const index = CLOSEOUT_TIP_ADJUST.payId.findIndex(
          (v) => v === responseToBE.paymentId
        );
        if (CLOVER_RESPONSE_IN_POST) {
          if (responseToBE?.isCloseOut) {
            respToBEForCloseOut = {
              clover_response: responseToBE?.response,
              transaction_ticket_no: myConfig.transaction_ticket_no,
              transaction_reference_no: myConfig.transaction_reference_no,
              reference_no: myConfig.reference_no,
              is_from_closeout: 1,
            };
          } else {
            respToBEForCloseOut = {
              clover_response: responseToBE,
              transaction_ticket_no: CLOSEOUT_TIP_ADJUST.ticketNo[index],
              transaction_reference_no:
                CLOSEOUT_TIP_ADJUST.transactionNo[index],
              is_from_closeout: 1,
            };
          }
        } else {
          if (responseToBE?.isCloseOut) {
            respToBEForCloseOut = `${
              myConfig.clover_closeout_response_url
            }?clover_response=${encodeURIComponent(
              responseToBE?.response
            )}&transaction_ticket_no=${
              myConfig.transaction_ticket_no
            }&transaction_reference_no=${
              myConfig.transaction_reference_no
            }&reference_no=${myConfig.reference_no}&is_from_closeout=1`;
          } else {
            respToBEForCloseOut = `${
              myConfig.clover_response_url
            }?clover_response=${encodeURIComponent(
              responseToBE
            )}&transaction_ticket_no=${
              CLOSEOUT_TIP_ADJUST.ticketNo[index]
            }&transaction_reference_no=${
              CLOSEOUT_TIP_ADJUST.transactionNo[index]
            }&is_from_closeout=1`;
          }
        }
      }
      let response;
      if (CLOVER_RESPONSE_IN_POST) {
        const args = IS_CLOSEOUT
          ? respToBEForCloseOut
          : {
              clover_response: responseToBE,
              transaction_ticket_no: myConfig.transaction_ticket_no,
              transaction_reference_no: myConfig.transaction_reference_no,
              is_full_refund: myConfig?.isFullRefund ?? "",
              ...(IS_PRINT_IMAGE && {
                reference_no: myConfig?.reference_no ?? "",
              }),
            };
        const formData = new FormData();
        Object.entries(args).map(([k, v]) => {
          formData.append(k, v);
        });
        const requestOptions = {
          method: "POST",
          body: formData,
          // headers: new Headers({
          //     'Authorization': 'Bearer ' + myConfig.access_code,
          // }), 
        };
        response = await fetch(
          responseToBE?.isCloseOut
            ? myConfig.clover_closeout_response_url
            : myConfig.clover_response_url,
          requestOptions
        );
      } else {
        response = await fetch(
          IS_CLOSEOUT
            ? respToBEForCloseOut
            : `${
                myConfig.clover_response_url
              }?clover_response=${encodeURIComponent(
                responseToBE
              )}&transaction_ticket_no=${
                myConfig.transaction_ticket_no
              }&transaction_reference_no=${
                myConfig.transaction_reference_no
              }&is_full_refund=${myConfig.isFullRefund}
              ${
                IS_PRINT_IMAGE
                  ? `&reference_no=${myConfig?.reference_no ?? ""}`
                  : ``
              }`,{
                 headers: {
                   'Authorization': 'Bearer ' + myConfig.access_code,
                   },
                 }
        );
      }
      const processCompleted = IS_CLOSEOUT ? responseToBE?.isCloseOut : true;
      console.log(
        "response sent to backend IS_CLOSEOUT:-",
        IS_CLOSEOUT,
        processCompleted,
        response
      );

      if (processCompleted) {
        console.log("*******dispose clover***************");
        if (cloverConnection?.cloverConnector) {
          cloverConnection.cloverConnector.dispose();
          setOtherState((p) => ({ ...p, statusToggle: false }));
        }
      }
    };
    sendRespToBE();
    if (IS_CLOSEOUT) setBulkTipAdjustResponse((p) => [...p, responseToBE]);
  }, [responseToBE]);

  const sendReqDetailsToBE = async (reqDetailsToBE, action, requestDetails) => {
    const actionList = [
      "sale",
      "auth",
      "auth_tip",
      "partial_refund",
      "full_refund",
      "void",
      "payment_receipt_print",
      "refund_receipt_print",
      "closeout",
    ];
    if (!myConfig?.clover_request_details_sending_url) return;
    const response = await fetch(
      requestDetails ||
        `${
          myConfig.clover_request_details_sending_url
        }?request_payload=${encodeURIComponent(
          reqDetailsToBE
        )}&transaction_ticket_no=${
          myConfig.transaction_ticket_no
        }&transaction_reference_no=${
          myConfig.transaction_reference_no
        }&app_id=${myConfig.applicationId}&action=${actionList[action]}`
        ,{
           headers: {
             'Authorization': 'Bearer ' + myConfig.access_code,
             },
         }
    );
    console.log("request_details sent to backend", response);
  };

  const setStatus = (message, reason) => {
    // decides how to display status
    console.log("setStatus", message, reason);
    if (typeof message === "object" && message !== null) {
      setOtherState((p) => ({
        ...p,
        statusArray: message,
        statusToggle: false,
        responseFail: false,
        refundSuccess: false,
        tipAdjust: false,
        vaultedCard: false,
        newCustomMessage: false,
        finalCustomMessage: false,
      }));
    } else if (message === "Printers") {
      setOtherState((p) => ({
        ...p,
        printers: reason,
        newCustomMessage: false,
        finalCustomMessage: false,
      }));
    } else if (
      message === "Sale Processed Successfully" ||
      message === "Auth Processed Successfully" ||
      message === "PreAuth Successful" ||
      message === "PreAuth Processed Successfully"
    ) {
      saleFinished(message);
    } else if (message === "Response was not a sale") {
      setOtherState((p) => ({
        ...p,
        responseFail: true,
        statusText: reason,
        statusToggle: true,
        inputOptions: null,
        refundSuccess: false,
        tipAdjust: false,
        vaultedCard: false,
        newCustomMessage: false,
        finalCustomMessage: false,
      }));

      setTimeout(() => {
        setOtherState((p) => ({ ...p, statusToggle: false }));
      }, 1200);
    } else if (reason === "Toggle") {
      statusToggle(message);
    } else {
      setOtherState((p) => ({
        ...p,
        statusToggle: true,
        statusText: message,
        challenge: false,
        saleFinished: false,
        vaultedCard: false,
        preAuth: false,
        inputOptions: null,
        responseFail: false,
        refundSuccess: false,
        tipAdjust: false,
        newCustomMessage: false,
        finalCustomMessage: false,
      }));
    }
  };

  const saleFinished = (message) => {
    // called when sale is finished
    if (message === "PreAuth Successful") {
      setOtherState((p) => ({ ...p, preAuth: true }));
    }
    setOtherState((p) => ({
      ...p,
      statusText: message,
      statusToggle: true,
      saleFinished: true,
      responseFail: false,
      refundSuccess: false,
      response: true,
      tipAdjust: false,
      vaultedCard: false,
    }));

    setTimeout(() => {
      setOtherState((p) => ({
        ...p,
        statusToggle: false,
        response: false,
      }));
    }, 1000);
  };

  const statusToggle = (message) => {
    // shows status for 1.5 seconds then closes
    if (message === "Card Successfully Vaulted") {
      setOtherState((p) => ({
        ...p,
        vaultedCard: true,
        refundSuccess: false,
        tipAdjust: false,
      }));
    } else if (message === "Refund Processed Successfully") {
      setOtherState((p) => ({
        ...p,
        refundSuccess: true,
        vaultedCard: false,
        tipAdjust: false,
      }));
    } else if (message === "Tip adjusted successfully") {
      setOtherState((p) => ({
        ...p,
        refundSuccess: false,
        vaultedCard: false,
        tipAdjust: true,
      }));
    } else {
      setOtherState((p) => ({
        ...p,
        refundSuccess: false,
        vaultedCard: false,
        tipAdjust: false,
      }));
    }
    setOtherState((p) => ({
      ...p,
      statusToggle: true,
      statusText: message,
      challenge: false,
      saleFinished: false,
      responseFail: false,
      inputOptions: null,
      newCustomMessage: false,
      finalCustomMessage: false,
    }));

    setTimeout(() => {
      setOtherState((p) => ({ ...p, statusToggle: false }));
    }, 1500);
  };

  const challenge = (challenge, request) => {
    // shows challenge as popup
    setOtherState((p) => ({
      ...p,
      statusToggle: true,
      statusText: challenge.message,
      challenge: true,
      request: request,
      inputOptions: null,
      challengeContent: challenge,
    }));
  };
  const closeStatus = () => {
    // closes status
    if (!otherState.challenge && !otherState.response) {
      setOtherState((p) => ({ ...p, statusToggle: false, inputOptions: null }));
    }
  };
  const inputOptions = (io) => {
    console.log("inputOptions", io);
    // sets input options
    setOtherState((p) => ({ ...p, inputOptions: io }));
  };

  const confirmSignature = (request) => {
    // shows popup for confirming signature
    closeStatus();
    let signature = request.signature;
    setOtherState((p) => ({
      ...p,
      showSignature: true,
      signatureRequest: request,
    }));

    const ctx = this.refs.canvas.getContext("2d");
    ctx.scale(0.25, 0.25);
    for (
      let strokeIndex = 0;
      strokeIndex < signature.strokes.length;
      strokeIndex++
    ) {
      let stroke = signature.strokes[strokeIndex];
      ctx.moveTo(stroke.points[0].x, stroke.points[0].y);
      for (
        let pointIndex = 1;
        pointIndex < stroke.points.length;
        pointIndex++
      ) {
        ctx.lineTo(stroke.points[pointIndex].x, stroke.points[pointIndex].y);
        ctx.stroke();
      }
    }
  };

  const customSuccess = (success) => {
    setOtherState((p) => ({
      ...p,
      customSuccess: success,
      newCustomMessage: false,
      finalCustomMessage: false,
    }));
  };

  const newCustomMessage = (message) => {
    setOtherState((p) => ({
      ...p,
      newCustomMessage: true,
      messageFromCustomActivity: message,
      customSuccess: false,
      finalCustomMessage: false,
    }));
  };

  const finalCustomMessage = (message) => {
    setOtherState((p) => ({
      ...p,
      finalCustomMessage: true,
      finalMessageFromCustomActivity: message,
      newCustomMessage: false,
      customSuccess: false,
    }));
  };

 

  React.useEffect(() => {
      console.log("myConfig", myConfig);
      console.log("myConfig Chdeck", myConfig.merchant_id , myConfig.employee_id , myConfig.client_id , myConfig.code);
      if(myConfig.merchant_id && myConfig.employee_id && myConfig.client_id && myConfig.code){
        console.log("Authenticated", true);
        SetIsAuthincation(true);
        setLoading(true)
    
      } else {
        SetIsAuthincation(false);
        setLoading(false)
        const validateAndInitialize = async () => {
          setInitialStaus("Authenticating...");
          const response = await fetch(
            `${myConfig.clover_token_validation_url}?clover_auth_token=${myConfig.clover_auth_token}`
          );
          const validationResult = await response.json();
          console.log("validateAndInitialize:--", response, validationResult);
          if (validationResult?.status !== "ok") {
            return setInitialStaus("Authentication Failed!!!");
          }
          setInitialStaus("Authenticated, Loading Device...");
          setCloverConnection(
            new Connect({
              toggleConnectionState: (connected) => {
                console.log("toggleConnectionState", connected);
                toggleConnectionState(connected);
              },
              setPairingCode: (code) => setPairingCode(code),
              setStatus: setStatus,
              challenge: challenge,
              tipAdded: (amount) => tipAdded(amount),
              store: store,
              closeStatus: closeStatus,
              inputOptions: inputOptions,
              confirmSignature: confirmSignature,
              customSuccess: customSuccess,
              newCustomMessage: newCustomMessage,
              finalCustomMessage: finalCustomMessage,
              setResponseToBE: setResponseToBE,
            })
          );
          //const devicesUrl = `${myConfig.devicesDomain}${myConfig.merchantId}/devices?access_token=${myConfig.access_code}`;
          const devicesUrl = `${myConfig.devicesDomain}${myConfig.merchantId}/devices`;
          const xhttp = new XMLHttpRequest();
          xhttp.onreadystatechange = function () {
            if (xhttp.readyState != 4) return; // Not there yet
            if (xhttp.status != 200) {
              console.log(
                `There has been an error) status: ${xhttp.status} readyState: ${xhttp.readyState}`
              );
              return;
            }
            console.log("xhttp.responseText", xhttp.responseText);
            // Request successful, read the response
            const newDevices = JSON.parse(xhttp.responseText);
            setDevices(newDevices);
            console.log("Devices", newDevices);
            connectToCloud(true);
            setInitialStaus("Device Loaded");
            // ... and use it as needed by your app.
          };
          xhttp.open("GET", devicesUrl, true);
          xhttp.setRequestHeader('Authorization', 'Bearer ' + myConfig.access_code);
          //xhttp.setRequestHeader("Content-type", "application/json");
          xhttp.send();
        };
        validateAndInitialize();
        const getParentHtmlString = (event) => {
          console.log("full event", event);
          if (event.origin.startsWith("https://ubooktoday.com")) {
            console.log("inside getParentHtmlString", event.data);
            setHtmlString(event.data);
          }
        };
        window.addEventListener("message", getParentHtmlString);
        // setTimeout(() => {
        //   console.log("*******closed parent node listner***************");
        //   window.removeEventListener("message", getParentHtmlString);
        // }, 1500);
        return () => {
          console.log("*******closed***************");
          if (cloverConnection?.cloverConnector) {
            cloverConnection.cloverConnector.dispose();
            setOtherState((p) => ({ ...p, statusToggle: false }));
          }
          window.removeEventListener("message", getParentHtmlString);
        };
      }

   
  }, []);

  React.useEffect(() => {
    if (!selectedKit) return;
    console.log("selectKit", selectedKit);
    selectDevice(selectedKit?.label?.props?.device);
  }, [selectedKit]);

  const selectDevice = (device) => {
    console.log("device selected: ", device);
    if (!device) return;
    cloverConnection.connectToDeviceCloud(
      myConfig.access_code,
      myConfig.merchantId,
      device.id
    );
    connectToCloud(false);
  };

  const closeCloudConnect = () => {
    connectToCloud(false);
  };

  const hasDevice = () => cloudConnect && devices.elements.length > 0;
  const displayDevices = () => {
    let devicesEl = (
      <div>
        {devices?.elements?.length > 0 ? "Connecting..." : initialStaus}
      </div>
    );
    let numDevices = 0;
    if (hasDevice()) {
      let _devices = Object.keys(devices.elements).map((device, i) => {
        if (
          devices.elements[device].deviceTypeName !== "GOLDENOAK" &&
          devices.elements[device].deviceTypeName !== "GOLDLEAF"
        ) {
          numDevices++;
          return {
            value: devices.elements[device].serial,
            label: <DeviceRow device={devices.elements[device]} />,
            device: devices.elements[device],
          };
        }
      });
      console.log("selectedDevice", _devices);
      devicesEl = (
        <Dropdown
          options={_devices}
          onChange={selectKit}
          value={selectedKit}
          placeholder="Select device"
        />
      );
      if (!selectedKit && _devices.length === 1) selectKit(_devices[0]); // auto select device if has only one
    } else {
      return devicesEl;
    }
    if (parseInt(numDevices) < 1) {
      devicesEl = (
        <div className="container_padding">
          <div className="close_popup" onClick={closeCloudConnect}>
            X
          </div>
          <div>
            I'm sorry, there are no available devices for this merchant.
          </div>
        </div>
      );
    }

    return devicesEl;
  };

  const isTipNull = (tipSuggestion) => {
    if (
      tipSuggestion == null ||
      !tipSuggestion.isEnabled ||
      tipSuggestion.percentage.length < 1
    ) {
      return null;
    }
    return tipSuggestion;
  };

  const setTipSuggestions = (request) => {
    let tipSuggestion1 = isTipNull(store.tipSuggestion1);
    let tipSuggestion2 = isTipNull(store.tipSuggestion2);
    let tipSuggestion3 = isTipNull(store.tipSuggestion3);
    let tipSuggestion4 = isTipNull(store.tipSuggestion4);
    if (
      (tipSuggestion1 == null) &
      (tipSuggestion2 == null) &
      (tipSuggestion3 == null) &
      (tipSuggestion4 == null)
    ) {
      return;
    } else {
      request.setTipSuggestions([
        tipSuggestion1,
        tipSuggestion2,
        tipSuggestion3,
        tipSuggestion4,
      ]);
    }
  };

  const makeSaleRequest = () => {
    //  returns transaction request for sale
    let externalPaymentID = clover.CloverID.getNewId();
    // store.getCurrentOrder().setPendingPaymentId(externalPaymentID);
    let request = IS_AUTH
      ? new clover.sdk.remotepay.AuthRequest()
      : new clover.sdk.remotepay.SaleRequest();
    request.setExternalId(externalPaymentID);
    request.setExternalReferenceId(externalPaymentID.substring(0, 10));
    // request.setAmount(
    //   formatter.convertFromFloat(parseFloat(TOTAL_AMOUNT).toFixed(2))
    // );
    request.setAmount(formatter.convertFromFloat(TOTAL_AMOUNT));
    // request.setTippableAmount(
    //   formatter.convertFromFloat(parseFloat(5.7).toFixed(2))
    // );
    // request.setTaxAmount(formatter.convertFromFloat(parseFloat(0).toFixed(2)));
    // request.setAllowOfflinePayment(store.getAllowOfflinePayments());
    // request.setForceOfflinePayment(store.getForceOfflinePayments());
    // request.setApproveOfflinePaymentWithoutPrompt(
    //   store.getApproveOfflinePaymentWithoutPrompt()
    // );
    // request.setCardEntryMethods(store.getCardEntryMethods());
    // request.setSignatureEntryLocation(store.getSignatureEntryLocation());
    // request.setSignatureThreshold(store.getSignatureThreshold());
    if (!IS_AUTH) {
      if (!TIP_SUGGESTIONS.length) {
        store.setTipMode(clover.sdk.payments.TipMode.NO_TIP);
      }
      request.setTipMode(store.getTipMode());
    }
    request.setSignatureEntryLocation(
      clover.sdk.payments.DataEntryLocation.ON_SCREEN
    );
    request.setSignatureThreshold(THRESHOLD_AMOUNT);
    // request.setTipAmount(store.getTipAmount());
    request.setDisablePrinting(store.getDisablePrinting());
    // request.setDisableReceiptSelection(store.getDisableReceiptOptions());
    // request.setDisableDuplicateChecking(store.getDisableDuplicateChecking());
    // request.setAutoAcceptPaymentConfirmations(
    //   store.getAutomaticPaymentConfirmation()
    // );
    // request.setAutoAcceptSignature(store.getAutomaticSignatureConfirmation());
    request.setAutoAcceptSignature(true);
    request.setApproveOfflinePaymentWithoutPrompt(true);
    request.setDisableDuplicateChecking(true);
    request.setCardEntryMethods(clover.CardEntryMethods.ALL);
    if (!IS_AUTH) {
      const tipSuggestions = TIP_SUGGESTIONS.map((v) => {
        let tipSuggestion = new clover.sdk.merchant.TipSuggestion();
        tipSuggestion.setIsEnabled(true);
        // tipSuggestion.setName(v);
        TIP_IN_PERCENT
          ? tipSuggestion.setPercentage(v)
          : tipSuggestion.setAmount(
              formatter.convertFromFloat(parseFloat(v).toFixed(2))
            );
        return tipSuggestion;
      });
      request.setTipSuggestions(tipSuggestions);
    }

    // setTipSuggestions(request);
    // if (this.card != null) {
    //   request.setVaultedCard(this.card.card);
    // }
    return request;
  };

  const startPayment = async () => {
    const request = makeSaleRequest();
    console.log("SaleRequest", request, "IS_AUTH:-", IS_AUTH);
    sendReqDetailsToBE(request, IS_AUTH ? 1 : 0);
    IS_AUTH
      ? cloverConnection.cloverConnector.auth(request)
      : cloverConnection.cloverConnector.sale(request);
  };

  const finishAdjustTip = ({ amount = 0, paymentId, orderId, index }) => {
    let tempTip = parseFloat(amount).toFixed(2);
    let taar = new clover.sdk.remotepay.TipAdjustAuthRequest();
    taar.setPaymentId(paymentId);
    taar.setOrderId(orderId);
    taar.setTipAmount(formatter.convertFromFloat(tempTip));
    console.log(
      "TipAdjustAuthRequest",
      taar,
      amount,
      paymentId,
      orderId,
      CLOSEOUT_TIP_ADJUST
    );
    if (CLOSEOUT_TIP_ADJUST.ticketNo[index]) {
      let requestDetails = `${
        myConfig.clover_request_details_sending_url
      }?request_payload=${encodeURIComponent(taar)}&transaction_ticket_no=${
        CLOSEOUT_TIP_ADJUST.ticketNo[index]
      }&transaction_reference_no=${
        CLOSEOUT_TIP_ADJUST.transactionNo[index]
      }&app_id=${myConfig.applicationId}&action=auth_tip`;
      sendReqDetailsToBE(null, null, requestDetails);
    } else {
      sendReqDetailsToBE(taar, 2);
    }
    cloverConnection.cloverConnector.tipAdjustAuth(taar);
  };

  const makeRefund = () => {
    let refund = new clover.sdk.remotepay.RefundPaymentRequest();
    refund.setAmount(
      formatter.convertFromFloat(parseFloat(TOTAL_AMOUNT).toFixed(2))
    );
    refund.setPaymentId(CLOVER_PAYMENT_ID);
    refund.setOrderId(CLOVER_ORDER_ID);
    refund.setFullRefund(IS_FULL_REFUND);
    console.log("RefundPaymentRequest", refund);
    sendReqDetailsToBE(refund, IS_FULL_REFUND ? 4 : 3);
    cloverConnection.cloverConnector.refundPayment(refund);
  };

  const voidPayment = () => {
    let vpr = new clover.sdk.remotepay.VoidPaymentRequest();
    vpr.setPaymentId(CLOVER_PAYMENT_ID);
    vpr.setOrderId(CLOVER_ORDER_ID);
    vpr.setVoidReason(clover.sdk.order.VoidReason.USER_CANCEL);
    console.log("VoidPaymentRequest", vpr);
    sendReqDetailsToBE(vpr, 5);
    cloverConnection.cloverConnector.voidPayment(vpr);
  };

  const showReceiptsSale = () => {
    let dror = new clover.sdk.remotepay.DisplayReceiptOptionsRequest();
    dror.setPaymentId(CLOVER_PAYMENT_ID);
    dror.setOrderId(CLOVER_ORDER_ID);
    CLOVER_REFUND_ID && dror.setRefundId(CLOVER_REFUND_ID);
    sendReqDetailsToBE(dror, CLOVER_REFUND_ID ? 7 : 6);
    cloverConnection.cloverConnector.displayReceiptOptions(dror);
  };

  const closeout = () => {
    let closeoutReq = new clover.sdk.remotepay.CloseoutRequest();
    closeoutReq.setAllowOpenTabs(false);
    closeoutReq.setBatchId(null);
    console.log("CloseoutRequest", closeoutReq);
    sendReqDetailsToBE(closeoutReq, 8);
    cloverConnection.cloverConnector.closeout(closeoutReq);
  };

  const printFromURL = () => {
    let pr = new clover.sdk.remotepay.PrintRequest();
    let node = document.getElementById("print-content-id");
    console.log("printFromURL", node);
    htmlToImage
      .toPng(node, {
        backgroundColor: "white",
        style: { color: "black" },
      })
      .then(function (dataUrl) {
        console.log("dataUrl", node, dataUrl);
        pr.setImageUrls([dataUrl]);
        console.log("PrintRequest - Print Image URL", pr);
        cloverConnection.cloverConnector.print(pr);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
    // console.log("PrintRequest - textToImage", PRINT_IMAGE);
    // pr.setImageUrls([PRINT_IMAGE]);
    // pr.setText(["with text"]);
    // console.log("PrintRequest - Print Image URL", pr);
    // cloverConnection.cloverConnector.print(pr);
  };

  const adjustTipBeforeCloseout = () => {
    console.log("adjustTipBeforeCloseout", CLOSEOUT_TIP_ADJUST);
    if (CLOSEOUT_TIP_ADJUST.payId.length === 0) {
      return closeout();
    }
    CLOSEOUT_TIP_ADJUST.payId.map((v, i) => {
      let paymentId = v;
      let orderId = CLOSEOUT_TIP_ADJUST.orderId[i];
      let amount = validateAmount(CLOSEOUT_TIP_ADJUST.amount[i]);
      if (paymentId && orderId) {
        finishAdjustTip({ amount, paymentId, orderId, index: i });
      }
    });
  };

  React.useEffect(() => {
    if (!bulkTipAdjustResponse?.length) return;
    const allTipAdjusted =
      bulkTipAdjustResponse.length === CLOSEOUT_TIP_ADJUST.payId.length;
    console.log("allTipAdjusted", allTipAdjusted);
    if (allTipAdjusted) closeout();
  }, [bulkTipAdjustResponse]);

  const changeDevice = () => {
    connectToCloud(true);
    cloverConnection.cloverConnector.dispose();
    toggleConnectionState(false);
  };

  const onCloseModal = () => {
    closeStatus();
    console.log('modal closed!');
    // setOtherState((p) => ({ ...p, statusToggle: false }));
  };

  React.useEffect(() => {
    //auto click the button and show toggle to indicate processing
    console.log("current button", autoClickRef.current);
    if (autoClickRef.current) {
      autoClickRef.current.click();
      setOtherState((p) => ({
        ...p,
        statusToggle: true,
        statusText: "Processing ...",
      }));
    }
  }, [autoClick]);

  const processAction = () => {
    const key = IS_PRINT_IMAGE
      ? "PRINT_IMAGE"
      : IS_CLOSEOUT
      ? "CLOSEOUT"
      : DROR_ACTION
      ? "DROR"
      : IS_TAAR
      ? "TAAR"
      : IS_REFUND
      ? "REFUND"
      : IS_VOID
      ? "VOID"
      : "PAYMENT";

    switch (key) {
      case "PAYMENT":
        return Boolean(TOTAL_AMOUNT) ? (
          <button
            ref={autoClickRef}
            className="clvr-button"
            onClick={startPayment}
          >
            Proceed Payment
          </button>
        ) : (
          <div>No Amount to process payment</div>
        );
      case "DROR":
        return Boolean(
          (DROR_ACTION === "Refund" ? CLOVER_REFUND_ID : DROR_ACTION) &&
            CLOVER_PAYMENT_ID &&
            CLOVER_ORDER_ID
        ) ? (
          <button
            ref={autoClickRef}
            className="clvr-button"
            onClick={showReceiptsSale}
          >
            {`Print ${DROR_ACTION} Receipt`}
          </button>
        ) : (
          <div>
            {`${
              DROR_ACTION === "Refund" ? `RefundId, ` : ``
            }Payment Id and Order Id is required to Print ${DROR_ACTION} Receipt`}
          </div>
        );
      case "TAAR":
        return Boolean(TAAR_AMOUNT && CLOVER_PAYMENT_ID && CLOVER_ORDER_ID) ? (
          <button
            ref={autoClickRef}
            className="clvr-button"
            onClick={() =>
              finishAdjustTip({
                amount: TAAR_AMOUNT,
                paymentId: CLOVER_PAYMENT_ID,
                orderId: CLOVER_ORDER_ID,
              })
            }
          >
            Proceed Tip Adjust AuthRequest
          </button>
        ) : (
          <div>
            Amount, Payment Id and Order Id is required to process Tip Adjust
            AuthRequest
          </div>
        );
      case "REFUND":
        return Boolean(TOTAL_AMOUNT && CLOVER_PAYMENT_ID && CLOVER_ORDER_ID) ? (
          <button
            ref={autoClickRef}
            className="clvr-button"
            onClick={makeRefund}
          >
            Proceed Refund
          </button>
        ) : (
          <div>
            {`Amount, Payment Id and Order Id is required to process Refund`}
          </div>
        );
      case "VOID":
        return Boolean(CLOVER_PAYMENT_ID && CLOVER_ORDER_ID) ? (
          <button
            ref={autoClickRef}
            className="clvr-button"
            onClick={voidPayment}
          >
            Proceed Void
          </button>
        ) : (
          <div>{`Payment Id and Order Id is required to process VOID payment`}</div>
        );
      case "CLOSEOUT": {
        let allHasSameLength = [
          CLOSEOUT_TIP_ADJUST.ticketNo.length,
          CLOSEOUT_TIP_ADJUST.transactionNo.length,
          CLOSEOUT_TIP_ADJUST.payId.length,
          CLOSEOUT_TIP_ADJUST.orderId.length,
          CLOSEOUT_TIP_ADJUST.amount.length,
        ].every((e) => e === CLOSEOUT_TIP_ADJUST.ticketNo.length);
        return allHasSameLength ? (
          <button
            ref={autoClickRef}
            className="clvr-button"
            onClick={adjustTipBeforeCloseout}
          >
            Proceed CloseOut
          </button>
        ) : (
          <div>{`Missing tip adjustment values`}</div>
        );
      }
      case "PRINT_IMAGE": {
        return (
          <>
            <HtmlPrint htmlString={htmlString} />
            <button
              ref={autoClickRef}
              className="clvr-button"
              onClick={printFromURL}
            >
              Print
            </button>
          </>
        );
      }

      default:
        return <></>;
    }
  };


  React.useEffect(() => {

    if (isAuthincation) {
     
      setTimeout(() => {
        window.location.href = `https://ubooktoday.com/api/update-clover-settings?merchant_id=${myConfig.merchant_id}&client_id=${myConfig.client_id}&code=${myConfig.code}`; 
      }, 2000);
    }
  }, [isAuthincation]);

  if(isAuthincation){
    return(
      <div className="app">
      {loading &&(
        <div className="loading-screen">
          {/* Customize your loading indicator */}
          <div className="spinner"></div>
          <h2>Authenticating Your App ...</h2>
        </div>
      )}
      
      {errorMessage && (
        <div className="error-message">
          <h2>Error: {errorMessage}</h2>
        </div>
      )}

      {updateSuccess
      && (
        // Your main app content goes here
        <div className="content">
          <h2>Your App Saved the Auth Token and Refresh Token</h2> 
          <p>You will be redirected now ...</p>
        </div>
      )}
    </div>

    )
  }

  return (
    <div>
      {connected ? (
        <>
          <span>
            Connected With:-
            <DeviceRow
              device={selectedKit?.label?.props?.device}
              changeDeviceButton={
                devices.elements.length > 1 && (
                  <button className="clvr-button" onClick={changeDevice}>
                    Change Device
                  </button>
                )
              }
            />
          </span>
          <br />
          <br />
          {processAction()}
          {!autoClick && setAutoClick(1)}
        </>
      ) : (
        displayDevices()
      )}
      {otherState.statusToggle && (
        <Modal {...{ msg: otherState.statusText, onClose: onCloseModal }} />
      )}
    </div>
  );
};

export default App;

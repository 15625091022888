import React from "react";
import ImageHelper from "../utils/ImageHelper";

const imageHelper = new ImageHelper();

const DeviceRow = ({ device, changeDeviceButton }) => {
  const [state, setState] = React.useState({
    imageSrc: "",
    name: "",
    showName: false,
  });
  React.useEffect(() => {
    if (!device) return;
    let imageSrc = imageHelper.getDeviceImage(device.deviceTypeName);
    let name = device?.productName ?? "";
    let showName = name.length > 0;
    setState((p) => ({ ...p, imageSrc, name, showName }));
  }, [device]);

  return (
    <div className="device_row">
      <div className="device_container">
        <div className="device_image_box">
          <img className="device_image" src={state.imageSrc} alt="device" />
          <span>
            {state.showName && <div className="device_name">{state.name}</div>}
            {device.serial}
          </span>
          <span style={{ marginRight: "7px" }}>{changeDeviceButton}</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DeviceRow);

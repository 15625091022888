const query = new URLSearchParams(window.location.search);

const conf = {
  applicationId: query.get("app_id"),
  cloverServer: query.get("clover_server"),
  merchantId: query.get("merchant_Id"),
  merchant_id: query.get("merchant_id"),
  employee_id: query.get("employee_id"),
  client_id: query.get("client_id"),
  code: query.get("code"),
  uriText: query.get("device_endpoint"),
  access_code: query.get("access_code"),
  devicesDomain: `${query.get("clover_server")}/v3/merchants/`,
  totalAmount: query.get("total_amount"),
  tipSuggestions: query.get("tip_suggestions"),
  isTipInPercentage: query.get("is_tip_in_percent"),
  transaction_ticket_no: query.get("transaction_ticket_no"),
  transaction_reference_no: query.get("transaction_reference_no"),
  clover_auth_token: query.get("clover_auth_token"),
  clover_token_validation_url: query.get("clover_token_validation_url"),
  clover_response_url: query.get("clover_response_url"),
  clover_closeout_response_url: query.get("clover_closeout_response_url"),
  clover_request_details_sending_url: query.get(
    "clover_request_details_sending_url"
  ),
  clover_signature_threshold_amount: query.get(
    "clover_signature_threshold_amount"
  ),
  isAuth: query.get("is_auth"),
  isTAAR: query.get("is_taar"),
  isRefund: query.get("is_refund"),
  isFullRefund: query.get("is_full_refund"),
  isVoid: query.get("is_void"),
  cloverPaymentId: query.get("clover_payment_id"),
  cloverOrderId: query.get("clover_order_id"),
  taarAmount: query.get("taar_amount"),
  drorAction: query.get("dror_action"),
  cloverRefundId: query.get("clover_refund_id"),
  autoPrint: query.get("auto_print"),
  isCloseout: query.get("is_closeout"),
  cloverResponseInPost: query.get("clover_response_in_post"),
  reference_no: query.get("reference_no"),
  print_image: query.get("print_image"),
};

export const myConfig = {
  applicationId: conf.applicationId,
  cloverServer: conf.cloverServer,
  merchantId: conf.merchantId,
  merchant_id: conf.merchant_id,
  employee_id: conf.employee_id,
  client_id: conf.client_id,
  code: conf.code,
  uriText: conf.uriText,
  access_code: conf.access_code,
  devicesDomain: conf.devicesDomain,
  totalAmount: conf.totalAmount,
  tipSuggestions: conf.tipSuggestions,
  isTipInPercentage: conf.isTipInPercentage,
  transaction_ticket_no: conf.transaction_ticket_no,
  transaction_reference_no: conf.transaction_reference_no,
  clover_auth_token: conf.clover_auth_token,
  clover_token_validation_url: conf.clover_token_validation_url,
  clover_response_url: conf.clover_response_url,
  clover_closeout_response_url: conf.clover_closeout_response_url,
  clover_request_details_sending_url: conf.clover_request_details_sending_url,
  clover_signature_threshold_amount: conf.clover_signature_threshold_amount,
  isAuth: conf.isAuth,
  isTAAR: conf.isTAAR,
  isRefund: conf.isRefund,
  isFullRefund: conf.isFullRefund,
  isVoid: conf.isVoid,
  cloverPaymentId: conf.cloverPaymentId,
  cloverOrderId: conf.cloverOrderId,
  taarAmount: conf.taarAmount,
  cloverRefundId: conf.cloverRefundId,
  drorAction: conf.drorAction,
  autoPrint: conf.autoPrint,
  isCloseout: conf.isCloseout,
  cloverResponseInPost: conf.cloverResponseInPost,
  reference_no: conf.reference_no,
  print_image: conf.print_image,
};

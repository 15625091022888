const Modal = ({ msg, onClose }) => {
  return (
    <div id="clover-modal" className="clvr-modal" onClick={onClose}>
      <div className="clvr-modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="clvr-close" onClick={onClose}>
          &times;
        </span>
        <p>{msg}</p>
      </div>
    </div>
  );
};

export default Modal;

import React from "react";

const htmlStringDemo = `
<div id="content">
    <div style="text-align: center;">
        <h2>lily employee6 </h2>
    </div>
    <table class="zui-table" id="receipt_header_table" style="font-size: 26px; font-weight: bolder;">
        <tbody>
            <tr>
                <td>Date:</td>
                <td id="receipt_date">2021-06-25</td>
                <td>Time:</td>
                <td id="receipt_time">01:06:35</td>
            </tr>
            <tr>
                <td>From:</td>
                <td id="receipt_start_date">
                    2021-01-11
                </td>
                <td>To:</td>
                <td id="receipt_end_date">
                    2021-01-17
                </td>
            </tr>
        </tbody>
    </table>
    <table class="zui-table" style="font-size: 26px; font-weight: bolder;">
        <thead>
            <tr style="background-color: #DDEFEF">
                <th>#</th>
                <th>Day</th>
                <th>Cost</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>Tuesday</td>
                <td>$35.00</td>
            </tr>
            <tr>
                <td colspan="2">Total</td>
                <td>
                    $35.00
                </td>
            </tr>
            <tr>
                <td colspan="2">Total Tips</td>
                <td>
                    $0.00
                </td>
            </tr>
        </tbody>
    </table>
    <table class="zui-table" style="font-size: 26px; font-weight: bolder;">
        <thead>
            <tr style="background-color: #DDEFEF">
                <th>Commission</th>
                <th id="employee_commission_value">
                    $21.00
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Cash</td>
                <td>$8.40</td>
            </tr>
            <tr>
                <td>Check</td>
                <td>$12.60</td>
            </tr>
            <tr>
                <td>Check+Tip</td>
                <td>$12.60</td>
            </tr>
        </tbody>
    </table>
</div>`;

const HtmlPrint = ({ htmlString = "" }) => {
  React.useLayoutEffect(() => {
    const allTables = document.getElementsByTagName("table");
    const promotionClass = document.getElementsByClassName("promotion");
    const closeOutTable = document.getElementById("daily_report_print_table");
    const qrcode = document.getElementById("qrcode");
    const allChank = document.querySelectorAll("div.chank");
    const walkinNote = document.getElementById("walkin_note");
    const date = document.getElementById("receipt_date");
    const startDate = document.getElementById("receipt_start_date");
    const endDate = document.getElementById("receipt_end_date");
    const finalTable = Array.from(allTables)
      .filter(
        (t) => t.style.fontSize == "26px" && t.style.fontWeight == "bolder"
      )
      .pop();
    const finalPromotionClass = Array.from(promotionClass)
      .filter(
        (t) => t.style.fontSize == "26px" && t.style.fontWeight == "bolder"
      )
      .pop();
    const finalDivChank = Array.from(allChank)
      .filter(
        (t) => t?.style?.fontSize == "26px" && t?.style?.fontWeight == "bolder"
      )
      .pop();
    if (finalTable) finalTable.style.marginBottom = "125px";
    if (finalPromotionClass) finalPromotionClass.style.marginBottom = "125px";
    if (closeOutTable) closeOutTable.style.marginBottom = "60px";
    if (finalDivChank) finalDivChank.style.marginBottom = "125px";
    if (walkinNote) walkinNote.style.marginBottom = "125px";
    if (date) date.style.whiteSpace = "nowrap";
    if (startDate) startDate.style.whiteSpace = "nowrap";
    if (endDate) endDate.style.whiteSpace = "nowrap";
    if (qrcode) {
      document.getElementById("print-inner-content-id").style.margin = "0 20px";
      qrcode.style.margin = "0 0 125px 0";
      qrcode.style.padding = "20px";
    }
  }, []);
  return (
    <div id="print-content-id">
      <div
        id="print-inner-content-id"
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
      <p>-</p>
    </div>
  );
};

export default HtmlPrint;
